a:focus,
input:focus,
select:focus,
textarea:focus,
button:focus,
.btn:focus {
  outline: none !important;
  box-shadow: 0;
}
body {
  color: #1d1d1d;
  background-color: #f3fafd;
  background-image: url("/img/bg-pattern.png");
  background-repeat: repeat;
}
.bg-primary {
  background-color: #1a9ede !important;
}
.bg-secondary {
  background-color: #868686 !important;
}
@font-face {
  font-family: "Kanit";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v7/nKKX-Go6G5tXcraQGwU.ttf)
    format("truetype");
}
@font-face {
  font-family: "Kanit";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v7/nKKS-Go6G5tXcraQI7mlVaQ.ttf)
    format("truetype");
}
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcoaS.ttf)
    format("truetype");
}
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4uPiWg.ttf)
    format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu52xP.ttf)
    format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBhc9.ttf)
    format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf)
    format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAw.ttf)
    format("truetype");
}
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
b,
strong,
.bold {
  font-weight: 700;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.hyphenate {
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: normal;
  -moz-hyphens: normal;
  hyphens: normal;
}
html.auth,
body.auth {
  height: 100%;
}
body.auth .bg-image {
  background-image: url("/img/example-bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
body.auth .form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
body.auth .form-signin .checkbox {
  font-weight: 400;
}
body.auth .form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
body.auth .form-signin .form-control:focus {
  z-index: 2;
}
body.auth .form-signin input[type="email"] {
  margin-bottom: -1px;
}
body.auth .form-signin input[type="password"] {
  margin-bottom: 10px;
}
.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}
header.navbar {
  height: 100px;
  border-bottom: 1px solid #f3fafd;
  background: #1a9ede;
}
header.navbar a.navbar-brand {
  height: 100%;
  padding: 0 30px;
}
header.navbar a.navbar-brand img.logo {
  height: 70px;
  width: auto;
}
header.navbar .navbar-toggler-container {
  top: 0.25rem;
  left: auto;
  height: 100%;
}
header.navbar .navbar-toggler-container button.navbar-toggler i {
  color: #ffffff;
}
header.navbar ul.navbar-nav {
  height: 100%;
  display: inline-block;
  margin: 0;
}
header.navbar ul.navbar-nav li.nav-item {
  height: 100%;
  display: inline-block;
}
header.navbar ul.navbar-nav li.nav-item button,
header.navbar ul.navbar-nav li.nav-item a.nav-link {
  height: 100%;
  color: #ffffff;
  padding: 0 30px;
  font-family: "Kanit", sans-serif;
  font-size: 14px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
header.navbar ul.navbar-nav li.nav-item button i,
header.navbar ul.navbar-nav li.nav-item a.nav-link i {
  margin-right: 0;
}
header.navbar ul.navbar-nav li.nav-item.signout {
  border-left: 1px solid #f3fafd;
}
header.navbar ul.navbar-nav li.nav-item.signout a.nav-link {
  color: #ffffff;
  text-transform: uppercase;
}
header.navbar ul.navbar-nav li.nav-item.toggler {
  border-left: 1px solid #f3fafd;
  background: #f18e00;
}
header.navbar ul.navbar-nav li.nav-item.toggler button {
  color: #ffffff;
}
@media (min-width: 768px) {
  header.navbar ul.navbar-nav li.nav-item button i,
  header.navbar ul.navbar-nav li.nav-item a.nav-link i {
    margin-right: 10px;
  }
}
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 100px 0 0;
  /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.sidebar ul.nav {
  border-top: 1px solid #efefef;
  padding: 15px 0;
}
.sidebar ul.nav.primary {
  border-top: 0;
}
.sidebar ul.nav.primary li.nav-item {
  padding: 15px 0;
}
.sidebar ul.nav li.nav-item {
  padding: 10px 0;
}
.sidebar ul.nav li.nav-item .nav-link {
  color: #1d1d1d;
  padding: 0 25px;
  font-family: "Kanit", sans-serif;
  text-transform: uppercase;
  border-left: 3px solid transparent;
}
.sidebar ul.nav li.nav-item .nav-link:hover {
  color: #1a9ede;
}
.sidebar ul.nav li.nav-item .nav-link:hover .feather {
  color: inherit;
}
.sidebar ul.nav li.nav-item .nav-link.active {
  color: #f18e00;
  border-left: 3px solid #f18e00;
}
.sidebar ul.nav li.nav-item .nav-link.active .feather {
  color: inherit;
}
.sidebar ul.nav li.nav-item .nav-link .feather {
  margin-right: 4px;
}
.sidebar ul.nav li.nav-item .nav-link i {
  margin-right: 10px;
}
.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}
.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .sidebar {
    top: 0;
  }
}
a.btn,
button.btn {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Kanit", sans-serif;
}
a.btn.btn-primary,
button.btn.btn-primary {
  background-color: #1a9ede;
  border-color: #1a9ede;
}
a.btn.btn-primary:hover,
button.btn.btn-primary:hover {
  background-color: #0d5481;
  border-color: #0d5481;
}
a.btn.btn-outline-primary,
button.btn.btn-outline-primary {
  border-color: #1a9ede;
  color: #1a9ede;
}
a.btn.btn-outline-primary:hover,
button.btn.btn-outline-primary:hover {
  background-color: #1a9ede;
  color: #ffffff;
}
.card {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid #efefef;
}
.card .card-img-top {
  height: 100%;
  max-height: 450px;
  overflow: hidden;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.card .card-img-top img {
  width: 100%;
  height: auto;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.card .card-img-overlay {
  height: 100%;
  max-height: 450px;
  overflow: hidden;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.card .card-img-overlay .badge {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  padding: 7px 14px;
}
.card .card-header {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  padding: 10px 15px;
  background: #efefef;
  color: #1a9ede;
  font-family: "Kanit", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.card .card-body {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  padding: 15px;
}
.card.bg-primary {
  background: transparent !important;
}
.card.bg-primary .card-header {
  background-color: #1a9ede;
  color: #ffffff;
}
.card.bg-primary .card-body {
  background-color: rgba(26, 158, 222, 0.2) !important;
}
table.table thead tr {
  border: 0;
}
table.table thead tr th {
  border: 0;
  padding: 15px 5;
}
table.table tbody tr {
  border-top: 1px solid #efefef;
  border-bottom: 0;
}
table.table tbody tr th {
  border: 0;
  padding: 15px 5;
}
table.table tbody tr td {
  font-weight: 400;
  border: 0;
  color: #868686;
  padding: 8px 5;
  font-size: 14px;
  vertical-align: middle;
}
table.table tbody tr td .thumbnail {
  width: 80px;
  max-width: 100%;
  height: 80px;
  max-height: 100%;
  overflow: hidden;
}
table.table tbody tr td .thumbnail img {
  height: auto;
  max-height: 100%;
  width: auto;
}
span.badge {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  padding: 7px 14px;
}
span.badge.bg-danger {
  background-color: rgba(235, 87, 87, 0.2) !important;
  color: #eb5757;
}
span.badge.bg-primary {
  background-color: rgba(26, 158, 222, 0.2) !important;
  color: #1a9ede;
}
span.badge.bg-warning {
  background-color: rgba(242, 201, 76, 0.2) !important;
  color: #f2c94c;
}
span.badge.bg-info {
  background-color: rgba(241, 142, 0, 0.2) !important;
  color: #f18e00;
}
span.badge.bg-success {
  background-color: rgba(39, 174, 96, 0.2) !important;
  color: #27ae60;
}

table.table tbody tr:hover {
  background: #efefef;
}
table.table.no-hover tbody tr:hover {
  background: none;
}
table.table.table-lg tbody tr td {
  padding: 8px 5;
  font-size: 16px;
}
.nav-tabs .nav-link {
  color: #1a9ede;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.tab-content {
  background-color: #ffffff;
}
form label.form-label {
  font-weight: 700;
}
form .input-group .input-group-text {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-color: #efefef;
}
form input.form-control {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-color: #efefef;
}
form input.form-control[type="text"]::placeholder,
form input.form-control[type="text"]::-webkit-input-placeholder,
form input.form-control[type="text"]::-ms-input-placeholder {
  color: #efefef;
}
form .form-file input[type="file"].form-file-input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-color: #efefef;
}
form .form-file label.form-file-label .form-file-text {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-color: #efefef;
}
form .form-file label.form-file-label .form-file-button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-color: #efefef;
}
form select.form-select {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-color: #efefef;
}
form textarea.form-control {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-color: #efefef;
}
.modal .modal-dialog .modal-content .modal-header {
  border: none;
}
.modal .modal-dialog .modal-content .modal-header h5.modal-title {
  font-size: 28px;
}
article.blog-post img.featured-image {
  width: 100%;
  height: auto;
  margin: 0 0 15px;
}
article.blog-post span.date {
  color: #868686;
}
article.blog-post h2 {
  margin-top: 40px;
}
article.blog-post h3 {
  margin-top: 25px;
}
article.blog-post p a {
  color: #1a9ede;
}
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #efefef;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #1a9ede;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
/*# sourceMappingURL=theme.min.css.map */
